.navbar {
  margin-bottom:20px;
}

.userInfo {
  pointer-events: none;
}

.nav-link {
  text-transform: none;
}

#mlbs4-megamenu,#mlbs4-mobilemenu{
  width:100%
}

.relative-wrapper {
  position: relative;
  height: 0;
  width: 0;
  background: transparent;
  overflow: visible;
}
.filterable {
  cursor: pointer;
}
.filterable .filters {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  background-color: #DDD;
  background-color: rgba(20, 20, 20, 0.85);
  list-style-type: none;
  font-size: 0.875em;
}
.filterable .filters li {
  white-space: nowrap;
  padding: 0.5em 1em;
  font-weight: 400;
  color: #454545;
  background: rgba(255, 255, 255, 0.75);
}
.filterable .filters li:hover {
  background: transparent;
  color: #FFF;
}
img {
  max-width:100%;
}
.header-image--search {
  position:inherit
}

#outerLoader {
  position:fixed;
  z-index:-1000;
  width: 100%;
  text-align: center;
  align-items: center;
}
#root {
  display: none;
  background: white;
}